import { ref } from "vue";
const useAddExit = queryFun => {
  const addShow = ref(false);
  const editShow = ref(false);
  const currentId = ref("");
  const record = ref({});
  const isView = ref(false);

  const showAddModal = () => {
    addShow.value = true;
  };
  const showEditModal = (id, _record, _isView = false) => {
    currentId.value = id;
    if (_record) {
      record.value = _record;
    }
    isView.value = _isView;
    editShow.value = true;
  };

  const hideAddModal = refresh => {
    addShow.value = false;
    if (refresh) {
      queryFun();
    }
  };
  const hideEditModal = refresh => {
    editShow.value = false;
    if (refresh) {
      queryFun();
    }
  };
  return {
    addShow,
    editShow,
    showAddModal,
    showEditModal,
    hideEditModal,
    hideAddModal,
    currentId,
    record,
    isView,
  };
};

export default useAddExit;
