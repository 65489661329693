<template>
  <div class="title">{{ $route.meta.title }}</div>
</template>

<script>
export default {
  name: "index",
};
</script>

<style scoped>
.title {
  border-left: 4px solid #1890ff;
  padding-left: 4px;
  font-weight: bold;
  font-size: 1em;
}
</style>
