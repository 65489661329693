<template>
  <a-tooltip placement="top">
    <template #title>
      <span>{{ title }}</span>
    </template>
    <a-button
      v-permission="perm"
      :class="btnClass"
      :type="type"
      @click="doClick"
    >
      <template #icon>
        <slot></slot>
      </template>
    </a-button>
  </a-tooltip>
</template>

<script>
import { toRefs } from "vue";

export default {
  name: "TipButton",
  emits: ["btnClick"],
  props: ["title", "btnClass", "type", "text", "icon", "perm"],
  setup(props, { emit }) {
    const doClick = () => {
      emit("btnClick");
    };
    return {
      ...toRefs(props),
      doClick,
    };
  },
};
</script>

<style scoped></style>
