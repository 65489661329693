import { ref, reactive } from "vue";

/**
 *
 * @param loadFun 加载数据的方法
 * @returns {{total: Ref<UnwrapRef<number>>, pageSizeOptions: UnwrapNestedRefs<string[]>, pageSizeChange: pageSizeChange, doQuery: doQuery, doRefresh: doRefresh, pageSize: Ref<UnwrapRef<number>>, page: Ref<UnwrapRef<number>>, currentChange: currentChange}}
 */
const usePagination = loadFun => {
  const page = ref(1);
  const pageSize = ref(20);
  const pageSizeOptions = reactive(["20", "50", "100"]);
  const currentChange = () => {
    loadFun(page.value, pageSize.value);
  };
  const pageSizeChange = () => {
    loadFun(page.value, pageSize.value);
  };
  const doQuery = () => {
    page.value = 1;
    loadFun(page.value, pageSize.value);
  };
  const doRefresh = () => {
    loadFun(page.value, pageSize.value);
  };
  return {
    page,
    pageSize,
    pageSizeOptions,
    pageSizeChange,
    currentChange,
    doQuery,
    doRefresh,
  };
};
export default usePagination;
